
import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { Title } from "react-admin";
export default () => (<Card>
  <Title title="Welcome" />
  <CardHeader title="Welcome to Bleustay PMS" />
  <CardContent>
  </CardContent>
</Card>)
  ;