import * as React from 'react';
import { useSelector } from 'react-redux';
import { Layout, LayoutProps } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { darkTheme, lightTheme } from './themes';
import useAuth from '../../auth/useAuth';
import { CircularProgress } from '@material-ui/core';
import icLogo from "../../images/ic_logo_text.png";
import { useState } from 'react';
import { useEffect } from 'react';
const Loading = () => (<div
    style={{ position: "absolute", marginLeft: "50vw", marginTop: "50vh" }}
>
    <img
        src={icLogo}
        style={{
            marginTop: -100,
            width: 200,
            display: "block",
            marginBottom: 50,
            marginLeft: -75,
        }}
    />
    <CircularProgress />
    <div style={{ marginTop: 10, marginLeft: -10, fontWeight: "bold" }}>
        Loading
    </div>
</div>);
export default (props) => {
    const theme = useSelector((state) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    const [path,setPath] = useState(undefined);
    useEffect(()=>{
        setPath(window.location.pathname);
    },[])
    const { auth } = useAuth();

    return !auth && path!=="/auth-callback" ? <Loading /> : <Layout {...props} appBar={AppBar} menu={Menu} theme={theme} />;
};
