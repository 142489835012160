const isLocalOrDev = window.location.href.includes("localhost") || window.location.href.includes("dev");
const isDev = window.location.href.includes("dev");
const isLocal = window.location.href.includes("localhost");
const isStaging = window.location.href.includes("stg");
const hasuraSecret = isLocalOrDev ? "2drH5MsdDIsNzxlhdEIAuioyNuJtktQLxhhnLuGEqH11QWsBz0q5ce0dgqXVSCU5" : isStaging ? "gS0WpS6Fgl0FFFNbTAWO1CT5K5c1qOLNqqhH2iss7jsPwo2cnzyb52QpA4dYwWHf" : "Pp1pqMRPjzGlaCsjnShpmIenTdewdsUrfTzMW2TAI5E7o4baJ2zTmGHoZf3lVrDR";
const hasuraUrl = isLocalOrDev ? "https://hasura.dev.bleustay.com/v1/graphql" : isStaging ? "https://hasura.stg.bleustay.com/v1/graphql" : "https://hasura.bleustay.com/v1/graphql";
const clientId = isLocal ? "08dd562a-2964-43ac-8a63-f62d71c62a2e" : isDev ? "08dd5a31-9f33-49ea-853d-805cc7162d71" : isStaging ? "08dd5a31-d219-4e9f-844c-d445d7ee2949" : "08dd5a32-16ee-4834-8ee0-951d4f24c2cd";
const loginUrl = isLocal ? "http://localhost:3000/api/sso/auth" : isDev ? "https://subscription.dev.rideum.io/api/sso/auth" : isStaging ? "https://subscription.stg.rideum.io/api/sso/auth" : "https://subscription.rideum.io/api/sso/auth";
const rideumAuthBackendUrl = isLocal ? "http://localhost:50001" : isDev ? "https://admin.dev.bleudine.com" : isStaging ? "https://admin.stg.bleudine.com" : "https://admin.bleudine.com";
const refreshTokenUrl = `${rideumAuthBackendUrl}/api/sso/token/refresh`
const codeExchangeUrl = `${rideumAuthBackendUrl}/api/sso/token`
const userProfileUrl = `${rideumAuthBackendUrl}/api/sso/auth/me`
const renewTokenPair = async () => {
  const refreshToken = window.localStorage.getItem("_refresh_token");
  if (!refreshToken) {
    throw new Error("Refresh token not found");
  }
  const body = new URLSearchParams();
  body.append("grant_type", "refresh_token");
  body.append("refresh_token", refreshToken);
  const response = await fetch(refreshTokenUrl, {
    method: "POST",
    body: body.toString(),
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
  if (!response.ok) {
    throw new Error("Failed to refresh token");
  }
  const tokenPair = await response.json();
  window.localStorage.setItem("_access_token", tokenPair.access_token);
  if (tokenPair.expire_at) {
    window.localStorage.setItem("_token_expiry", tokenPair.expire_at);
  }
  return tokenPair;
};
export const apiConfig = {
  url: window.location.href.includes("localhost") ? "http://localhost:50003" : window.location.href.includes("dev") ? "https://api.dev.bleudine.com" :
    window.location.href.includes("stg") ? "https://api.stg.bleudine.com" : "https://api.bleudine.com",
  path: "/api",
  rideumHotelUrl: window.location.href.includes("localhost") || window.location.href.includes("dev") ? "https://hasura.dev.bleustay.com/v1/graphql" :
    window.location.href.includes("stg") ? "https://hasura.stg.bleustay.com/v1/graphql" : "https://hasura.bleustay.com/v1/graphql",

  getBleudineToken: function () {
    const user = JSON.parse(localStorage.getItem("bleudine-user"));
    return user ? user.token : "";
  },
  getRideumToken: function () {
    const token = localStorage.getItem("token");
    return token ? token : "";
  },
  jwt: function () {
    return new Promise((resolve, reject) => {
      const expiry = localStorage.getItem("_token_expiry");
      if (!expiry) {
        reject("Invalid session");
        return;
      }
      let currentTime = new Date().getTime();
      if (currentTime > expiry) {
        resolve(localStorage.getItem("_access_token"));
        return;
      }
      return renewTokenPair()
        .then((pair) => {
          localStorage.setItem("_token_expiry", pair.expire_at);
          localStorage.setItem("_access_token", pair.access_token);
          resolve(pair.access_token);
        })
        .catch(reject)
    });
  },
  hasuraSecret: hasuraSecret,
  hasuraUrl: hasuraUrl,
  clientId,
  loginUrl,
  codeExchangeUrl,
  userProfileUrl,
  refreshTokenUrl
};
