import { apiConfig } from "../api/apiConfig";



export const authProvider = {
    checkAuth() {
        const isAuthenticated = localStorage.getItem("auth") ? true : false;
        if (isAuthenticated)
            return Promise.resolve();
        if (window.location.pathname != "/auth-callback") {
            window.localStorage.removeItem("_code_verifier");
            const domain = window.location.origin;
            const redirectUri = `${domain}/auth-callback`;
            const code_verifier = window.crypto.randomUUID();
            window.localStorage.setItem("_code_verifier", code_verifier);
            window.crypto.subtle.digest("SHA-256", new TextEncoder().encode(code_verifier))
                .then(hashedBytes => {
                    let code_challenge_value = window.btoa(String.fromCodePoint(...new Uint8Array(hashedBytes)));
                    const searchParams = new URLSearchParams();
                    searchParams.append("grant_type", "authorization_code");
                    searchParams.append("code_challenge_method", "S256");
                    searchParams.append("code_challenge_value", code_challenge_value);
                    searchParams.append("redirect_uri", redirectUri);
                    searchParams.append("client_id", apiConfig.clientId);
                    window.location.href = `${apiConfig.loginUrl}?${searchParams.toString()}`
                });
        }
        return Promise.resolve();
    },
    logout() {
        localStorage.removeItem('bleudine-user');
        localStorage.removeItem('auth');
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    getPermissions() {
        return Promise.resolve();
    }
}