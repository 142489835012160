import { CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { apiConfig } from '../api/apiConfig';
import useAuth from './useAuth';
export default function Callback() {
    const [error, setError] = useState(undefined);
    const { updateAuth, clearAuth } = useAuth();
    const handleError = (e) => {
        setError(e);
        localStorage.clear();
        clearAuth();
        setTimeout(() => {
            window.location.href = "/";
        }, 3000);
    };
    useEffect(() => {
        const url = new URL(window.location);
        let authCode = url.searchParams.get("code");
        const domain = window.location.origin;
        const redirectUri = `${domain}/auth-callback`;
        const codeVerifier = localStorage.getItem("_code_verifier");
        const body = new URLSearchParams();
        body.append("grant_type", "authorization_code");
        body.append("authorization_code", authCode);
        body.append("client_id", apiConfig.clientId);
        body.append("redirect_uri", redirectUri);
        body.append("code_verifier", codeVerifier);
        fetch(apiConfig.codeExchangeUrl, {
            method: "POST",
            body: body.toString(),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }).then(response => {
            if (!response.ok) {
                throw new Error("Error occur during code exchange")
            }
            window.localStorage.removeItem("_code_verifier");
            return response.json().then(tokens => {
                if (tokens.refresh_token) {
                    window.localStorage.setItem("_refresh_token", tokens.refresh_token);
                    window.localStorage.setItem("_access_token", tokens.access_token);
                    window.localStorage.setItem("_token_expiry", tokens.expire_at);
                    //grab user profile?
                    return fetch(apiConfig.userProfileUrl, {
                        method: "GET",
                        headers: { Authorization: `Bearer ${tokens.access_token}` }
                    }).then(res => {
                        if (!res.ok) {
                            throw new Error("Failed to get user profile");
                        }
                        return res.json().then(authInfo => {
                            updateAuth(authInfo);
                            window.location.href = "/";
                        }).catch(handleError);
                    }).catch(handleError);
                }
            })
        }).catch(handleError);

    }, []);

    return error ? <div>{error}</div> : <CircularProgress />;
}