import React from "react";
import { Route } from "react-router-dom";
import Configuration from "./resources/configuration/Configuration";
import ChangePassword from "./resources/password/ChangePassword";
import Callback from "./auth/Callback";

export default [
  <Route exact path="/configuration" component={Configuration} />,
  <Route exact path="/password/change" component={ChangePassword} />,
  <Route exact path="/auth-callback" component={Callback} />
];
