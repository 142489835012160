import { InputAdornment } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SearchIcon from "@material-ui/icons/Search";
import jsonExport from "jsonexport/dist";
import { useNotify } from 'ra-core';
import React, { Fragment, useEffect, useState } from "react";
import {
  Datagrid,
  EditButton,
  EmailField,
  FunctionField,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  downloadCSV,
} from "react-admin";
import { useHistory } from "react-router-dom";
import { bleudineMerchantApi } from "../../api/bleudineMerchantApi";
import { tenantStatus } from "../../models/tenantStatus";

const tenantFilters = [
  <TextInput
    source="email,name,phone_no,region,city,country"
    label="Search"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_tenant_status"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
];

const exporter = (records) => {
  const forExport = records.map((record) => {
    return {
      name: record.name,
      email: record.email,
      phone_no: record.phone_no,
      country: record.e_country.comment,
      status: record.e_tenant_status.comment,
    };
  });
  jsonExport(
    forExport,
    {
      headers: ["name", "email", "phone_no", "country", "status"],
    },
    (err, csv) => {
      downloadCSV(csv, "tenants");
    }
  );
};
const TenantGroupsList = (props) => {
  const history = useHistory();
  const notify = useNotify();
  const [mappedIds, setMappedIds] = useState([]);

  useEffect(() => {
    bleudineMerchantApi.getAll().then(
      (merchants) => {
        const mappedIds = merchants.map(merchant => merchant.id);
        setMappedIds(mappedIds);

      },
      (error) => {
          notify(error);
      }
  );
  },[]);

  return (
    <Fragment>
      {mappedIds ? (
            <List
            sort={{ field: "created_at", order: "Desc" }}
            filters={tenantFilters}
            bulkActionButtons={false}
            filterDefaultValues={{mapped_id:mappedIds}}
            exporter={exporter}
            {...props}
          >
            <Datagrid>
              <TextField source="name" label="ra.column.name" />
              <EmailField source="email" label="ra.column.email" />
              <TextField source="phone_no" label="ra.column.phone_no" />
              <TextField source="city" label="ra.column.city" />
              <TextField source="country" label="ra.column.country" />
              <FunctionField
                sortBy="status"
                label="Status"
                render={(record) => (
                  <Chip
                    label={record.status}
                    style={{
                      color: "white",
                      background: tenantStatus.getColor(record.status),
                    }}
                  />
                )}
              />
              <EditButton style={{ float: "right" }} basePath="/tenant_groups" />
            </Datagrid>
          </List>
      ) :null}
    </Fragment>
  );
};

export default TenantGroupsList;
