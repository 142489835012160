import React, { useState, useContext, useEffect } from "react";
import { SaveButton } from 'react-admin';
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { userApi } from '../../api/userApi';
import { DataProviderContext } from "react-admin";
import { bleudineUserApi } from "../../api/bleudineUserApi";
import { userStatus } from '../../models/userStatus';
import { tenantApi } from "../../api/tenantApi";
import { tenantGroupApi } from "../../api/tenantGroupApi";

const CustomSaveButton = (formProps) => {
  const notify = useNotify();
  const redirect = useRedirect();
  let { values } = useFormState();
  const [saving, setSaving] = React.useState(false);
  const dataProvider = useContext(DataProviderContext);

  const tenant_id = values.tenant_id;
  const tenant_groups_id = values.tenant_groups_id;
  const id = values.id;
  const email = values.email;
  const role = values.role;
  const first_name = values.first_name;
  const last_name = values.last_name;
  const status = values.status;

  const handleCreateClick = () => { debugger
    setSaving(true);
    const user =
    {
      accountType: 1,
      email: email,
      phoneNumber: "",
      displayName: first_name + " " + last_name,
      firstName: first_name,
      lastName: last_name,
      birthDate: "",
      countryOrigin: "",
      remarks: "",
      imageFileName: "",
      imageBase64: "",
      imageUrl: "",
      type: userStatus.getEnum(role.toLowerCase()),
      status: values.status
    }

    if(role != "manager")
    {
      tenantApi.getTenant(tenant_id).then(tenant => {
        tenantGroupApi.getTenantGroup(tenant.data.tenants[0].tenant_group_id).then(tenantGroup => {
          bleudineUserApi.createUser(tenantGroup.data.tenant_groups[0].mapped_id, user).then(newUser => {
            redirect(`/users`);
            notify("User Created");
          }).catch(error => {
            setSaving(false);
            notify(error);
          });
        }).catch(error => {
          setSaving(false);
          notify(error);
        });
  
      }).catch(error => {
        setSaving(false);
        notify(error);
      });
    }
    else{
      tenantGroupApi.getTenantGroup(tenant_groups_id).then(tenantGroup => {
        bleudineUserApi.createUser(tenantGroup.data.tenant_groups[0].mapped_id, user).then(newUser => {
          redirect(`/users`);
          notify("User Created");
        }).catch(error => {
          setSaving(false);
          notify(error);
        });
      }).catch(error => {
        setSaving(false);
        notify(error);
      });
    }

  }

  const handleUpdateClick = () => {
    const user =
    {
      displayName: first_name + " " + last_name,
      firstName: first_name,
      lastName: last_name,
      birthDate: "",
      countryOrigin: "",
      remarks: "",
      imageFileName: "",
      imageBase64: "",
      imageUrl: "",
      status: values.status,
      type: userStatus.getEnum(role.toLowerCase())
    }
    setSaving(true);

    bleudineUserApi.updateUser(values.mapped_id, user)
      .then((updatedUser) => {
        redirect(`/users`);
        notify("User updated");
      }
      )
      .catch(error => {
        setSaving(false);
        notify(error);
      });
  }

  return <SaveButton {...formProps} saving={saving} disabled={saving} onSave={values.id != undefined ? handleUpdateClick : handleCreateClick} />;
};

export default CustomSaveButton;